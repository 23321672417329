import type { AppState } from "~/app";

export const initialState: AppState = {
  app: {
    active: false,
    onboarding: {
      active: false,
      step: 1,
      suggestions: {}
    }
  },
  level: {
    name: "",
    description: null,
    icon: "https://cdn-prizify.xco.agency/svg/bronze.svg",
    required_points: 1,
    points_boost: 1,
    cashback_percentage: 0,
    discount_percentage: 0,
    points_conversion: undefined,
    points_conversion_rate: undefined
  },
  challenge: {
    name: "",
    description: null,
    points: 1,
    icon: "https://cdn-prizify.xco.agency/svg/cart6.svg",
    repeat: 1,
    scheduled: false,
    status: "active",
    type: "normal",
    childrens: [],
    start_date: new Date(),
    end_date: new Date(new Date().setDate(new Date().getDate() + 3)),
    trigger: {
      rules: {}
    },
    trigger_id: null
  },
  settings: {
    terms_link: "http://shopify.com/store/terms",
    points_conversion: {
      points: 100,
      conversion_rate: 10
    }
  },
  shop: {
    currency_code: "USD",
    points_conversion: 100,
    conversion_rate: 10,
    terms_link: null
  },
  triggers: [],
  errors: {},
  saved_onboarding_challenge: []
};
